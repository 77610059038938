import "./App.css";

import AutoScroll from "./components/AutoScroll";
import Blob from "./components/Blob";
import ContactForm from "./components/ContactForm";
import FlipCard from "./components/FlipCard";
import Navbar from "./components/Navbar";
import React from "react";
import Projects from "./components/Projects";

const models = [
  {
    modelPath: "/assets/static/models/laptop.glb",
    modelPos: { x: 0, y: 0, z: 0 },
    modelScale: { x: 2.5, y: 2.5, z: 2.5 },
    title: "Technology",
    description:
      "Technology is my life. From computers, phones, speakers, to touch screen coffee makers I love it all. Ive always been fascinated with tech and my love for it grows each and every day.",
  },
  {
    modelPath: "/assets/static/models/shield.glb",
    modelPos: { x: 0, y: -1, z: 0 },
    modelScale: { x: 0.4, y: 0.4, z: 0.4 },
    title: "Gaming",
    description:
      "Ever since I was a little boy Ive had an obsession with Zelda. My first Zelda game was Ocarina Of Time on the Nintendo 64. Im love it so much that I create maps for the Source 2 engine by recreating the dungeons from the N64 game.",
  },
  {
    modelPath: "/assets/static/models/money.glb",
    modelPos: { x: 0, y: -0.2, z: 0 },
    modelScale: { x: 6, y: 6, z: 6 },
    title: "Investing",
    description:
      "On my down time I like to learn new ways to invest and grow my wealth. Wether that be knowledge, money, or physical gain. I heavily invest in myself and strive to grow every single day.",
  },
  {
    modelPath: "/assets/static/models/earth.glb",
    modelPos: { x: 0, y: 0, z: 0 },
    modelScale: { x: 1, y: 1, z: 1 },
    title: "Traveling",
    description:
      "Recently I've been traveling more often and decided to take a trip to Europe to visit Spain, France, Rome, and Italy. It was a once in a lifetime opportunity that I will never forget. I'd like to visit Portugal or Paris next.",
  },
];

function App() {
  return (
    <div id="home" className="App">
      <AutoScroll sections={["home", "about", "projects", "contact"]} />

      <section>
        <div className="content-wrapper-home">
          <Navbar />
          <h1>Simple. Elegant. Unique.</h1>
          <p>
            I enjoy creating modern and innovative digital experiences. Dive
            into my portfolio to explore how I make creativity meet simplicity
            and functionality.
          </p>
        </div>
        <div>
          <div className="frosted-glass-container">
            <div className="frosted-glass" />
          </div>
          <div className="blob-container">
            <Blob />
          </div>
        </div>
      </section>

      <section id="about">
        <div className="content-wrapper-about">
          <h2>About Me</h2>
          <p
            style={{
              color: "white",
              fontSize: "1.25rem",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            I'm a well-rounded Full-Stack Developer and Product/Project Manager
            with over 7 years of experience in designing, developing, and
            integrating dynamic frontend and backend solutions. My journey spans
            managing projects that harmonize information technology, business
            strategies, and design to deliver impactful user experiences. I
            excel at turning complex challenges into innovative solutions,
            leading teams to success, and driving the seamless implementation of
            best practices across cutting-edge technologies.
          </p>
          <div className="about-cards">
            {models.map((model, index) => (
              <FlipCard
                key={index}
                modelPath={model.modelPath}
                modelPos={model.modelPos}
                modelScale={model.modelScale}
                title={model.title}
                description={model.description}
              />
            ))}
          </div>
        </div>
      </section>

      <section id="projects">
        <div className="content-wrapper-projects">
          <Projects />
        </div>
      </section>

      <section id="contact">
        <div className="content-wrapper-contact">
          <ContactForm />
        </div>
      </section>
    </div>
  );
}

export default App;
