import React, { useState } from "react";
import "./Projects.css";

const projects = [
  {
    title: "Little Lemon Project",
    description:
      "This was my first complete react project for my Meta Front-End Developer Certification.",
    imageSrc: "https://via.placeholder.com/400x300",
    link: "/little-lemon-project/",
  },
  {
    title: "Project Two",
    description: "A brief description of the second project.",
    imageSrc: "https://via.placeholder.com/400x300",
    link: "#",
  },
  {
    title: "Project Three",
    description: "A brief description of the third project.",
    imageSrc: "https://via.placeholder.com/400x300",
    link: "#",
  },
];

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleProjectClick = (link) => {
    window.open(link, "_blank"); // Open the link in a new tab
  };

  return (
    <section className="carousel-container">
      <h2>Projects</h2>
      <div className="carousel">
        <button className="carousel-arrow left-arrow" onClick={handlePrev}>
          &#8592;
        </button>
        <div
          className="carousel-item"
          onClick={() => handleProjectClick(projects[currentIndex].link)}
          style={{ cursor: "pointer" }}
        >
          <img
            src={projects[currentIndex].imageSrc}
            alt={projects[currentIndex].title}
            className="carousel-image"
          />
          <div className="carousel-text">
            <h3>{projects[currentIndex].title}</h3>
            <p>{projects[currentIndex].description}</p>
          </div>
        </div>
        <button className="carousel-arrow right-arrow" onClick={handleNext}>
          &#8594;
        </button>
      </div>
    </section>
  );
};

export default Projects;
